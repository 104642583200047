
import { mmcAjax } from '@/common/js/mmcModule';

/**
 * 获取短信
 * @param {object} params
 * @param {string} params.phone 手机号
 * @param {string} params.code_scene 验证码
 * @param {string} params.country_code 国家标识
 * @param {string} params.verify_code_scene 渠道标识
 * @param {string} params.auth_key 获取验证码标识 jishihelp_h5
 **/

export function getMsgCode(params) {
    const url = `${window.APIBasePath}mmc/passport/sms/send`;
    return mmcAjax({
        method: 'POST',
        url,
        crossDomain: true,
        data: params,
    });
}

/**
 * 刷新图形验证码
 * @param {object} params
 * @param {string} params.scene 手机号
 * @param {string} params.country_code 验证码
 * @param {string} params.phone 国家标识 'CN'
 * @param {string} params.account_type 国家标识 'phone'
 *
 **/

export function postRefreshPicCode(params) {
    const url = `${window.APIBasePath}mmc/passport/common/refresh-pic-code`;
    return mmcAjax({
        method: 'POST',
        url,
        crossDomain: true,
        data: params,
    });
}


/**
 * 换绑提交
 * @param {object} params
 * @param {string} phone 手机号
 * @param {string} sms_code 手机
 * @param {string} country_code 国家标识
 * @param {string} auth_key 获取验证码标识 jishihelp_h5
 **/

export function postBindPhone(params) {
    const { phone, sms_code, bindType } = params;
    let url = `${window.APIBasePath}mmc/passport/change-phone`;
    if (bindType === 'bind_phone') {
        url = `${window.APIBasePath}mmc/passport/bind-phone`;
    }

    return mmcAjax({
        method: 'POST',
        url,
        crossDomain: true,
        data: {
            phone,
            sms_code,
            country_code: 'CN',
            auth_key: 'jishihelp_h5'
        }
    });
}
