<template>
    <div class="bind-phone" v-if="isLoad">
        <label class="title bold">绑定手机号码</label>

        <div class="form-container">
            <div class="item-group">
                <label class="label bold">手机号</label>
                <input v-model="phone" type="tel" maxlength="11" placeholder="填写你的手机号" />
            </div>

            <div class="item-group">
                <label class="label bold">验证码</label>
                <div class="code-content">
                    <input v-model="sms_code" class="msg-code" type="tel" maxlength="4" placeholder="填写验证码" />

                    <span v-if="!startCountDown" @click="sendmMsgCode" class="code-btn green">发送验证码</span>
                    <span v-else class="code-btn gary">{{time}}s后重新获取</span>
                </div>
            </div>
        </div>

        <button class="btn" @click="submitForm">提 交</button>

        <div class="pic-modal" v-if="showPicModal">
            <div class="pic-body">
                <div class="pic-title">填写图片验证码</div>
                <span class="close-btn" @click="hidePicModal"></span>

                <img
                    v-if="base_64_pic_code"
                    v-lazy="base_64_pic_code"
                    class="pic-img-code" />
                    <p class="refresh-code">
                        <span @click="refreshPicCode">看不清，刷新验证码</span>
                    </p>

                <div class="cell-bt">
                    <input
                        v-model="input_pic_code"
                        maxlength="4"
                        type="tel"
                        class="pic-code-input"
                    />
                    <button class="submit-btn" @click="picSendmMsgCode">确认</button>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { getMsgCode, postBindPhone, postRefreshPicCode } from './js/api.js';
import { authChecker } from '@/common/js/mmcModule';
export default {
    name: 'bindPhone',
    data () {
        return {
            login_phone: '',
            isLoad: false,
            sms_code: '',
            phone: '',
            base_64_pic_code: '',
            pic_code: '',

            input_pic_code: '',
            showPicModal: false,

            time: 59,
            sendMsgText: '发送验证码',
            isSendMsg: false,
            startCountDown: false,
        }
    },
    computed: {
        bindType() {
            return this.login_phone ? 'alter_phone' : 'bind_phone';
        }
    },
    created() {
        authChecker.check({ notneedphone: 1 }, res => {
            this.login_phone = res.data.attr.phone;
            this.isLoad = true;
        });
    },
    methods: {
        submitForm() {
            console.log();
            const { sms_code } = this;
            const phone = this.phone.trim();
            const params = {
                phone,
                sms_code,
                bindType: this.bindType
            }

            if(!phone) {
                alertMsg('请输入手机号');
                return;
            }
            const reg =  /^1\d{10}$/;
            if(!reg.test(phone)) {
                alertMsg('请输入正确的手机号');
                return;
            }

            if(!sms_code) {
                alertMsg('请输入短信验证码');
                return;
            }

            postBindPhone(params).then(res => {
                
                if (res.code === 0) {
                    alertMsg("提交成功", 1500);

                    setTimeout(() => {
                        this.modifyPhoneCallback(phone);
                    }, 1500)
                } else {
                    alertMsg(res.msg);
                }
            })
        },

        sendmMsgCode() {
            if(this.isSendMsg) return;
            if(this.startCountDown) return;

            const phone = this.phone.trim();
            if(!phone) {
                alertMsg('请输入手机号');
                return;
            }
            const reg =  /^1\d{10}$/;
            if(!reg.test(phone)) {
                alertMsg('请输入手机号');
                return;
            }

            this.isSendMsg = true;
            this.getCode();
        },

        countDownTime() {
            let countDownIntervla = setInterval(() => {
                let time = this.time;
                if (time <= 1) {
                    this.startCountDown = false;
                    this.isSendMsg = false;
                    this.time = 60;
                    clearInterval(countDownIntervla);
                    return
                }
                else {
                    this.time = --time;
                }
            }, 1000)
        },

        refreshPicCode() {
            let params = {
                phone: this.phone.trim(),
                scene: this.bindType,
                country_code: 'CN',
                account_type: 'phone'
            };
            postRefreshPicCode(params).then(res => {

                if (res.code === 0 && res.data.base_64_pic_code) {

                    let pic = res.data.base_64_pic_code;
                    if (pic.indexOf('data:image/png;base64') === -1) {
                        pic = `data:image/png;base64,${pic}`;
                    }
                    this.base_64_pic_code = pic;
                    this.pic_code = res.data.pic_code;

                } else {
                    alertMsg(res.msg);
                }
            })
        },

        picSendmMsgCode() {
            if(this.isSendMsg) return;
            if(this.startCountDown) return;

            const input_pic_code = this.input_pic_code.trim();
            if (!input_pic_code) {
                alertMsg('请输入图形验证码');
                return;
            }

            if (input_pic_code !== this.pic_code) {
                alertMsg('请输入正确的验证码');
                return;
            }

            this.isSendMsg = true;
            this.showPicModal = false;
            this.getCode();
        },

        getCode() {
            let params = {
                phone: this.phone.trim(),
                verify_code_scene: this.bindType,
                country_code: 'CN',
                auth_key: 'jishihelp_h5'
            };
            if (this.base_64_pic_code) {
                params.pic_code = this.pic_code;
            }

            getMsgCode(params)
            .then(res => {
                this.isSendMsg = false;

                if (res.code === 0 && res.data.phone_verify_code_out) {
                    alertMsg('短信发送成功', 1000);
                    this.startCountDown = true;
                    this.countDownTime();
                    return;
                }

                if (res.code === 0 && res.data.base_64_pic_code) {
                    let pic = res.data.base_64_pic_code;
                    if (pic.indexOf('data:image/png;base64') === -1) {
                        pic = `data:image/png;base64,${pic}`;
                    }

                    this.base_64_pic_code = pic;
                    this.pic_code = res.data.pic_code;
                    this.showPicModal = true;
                    return;
                }
                alertMsg(res.msg);

            }).catch(() => {
                this.isSendMsg = false;
            })
        },

        hidePicModal() {
            this.showPicModal = false;
        },

        modifyPhoneCallback(phone) {
            let phone_number = phone.substr(0, 3) + '****' + phone.substr(phone.length - 4);
            this.$router.push({
                path: '/fund/my/phonesuccess',
                query: { phone: phone_number }
            });
        }
    }
}
</script>

<style lang='less' scoped>
.bind-phone {
    padding: 30px 20px;
}

.bold {
    font-weight: 600;
}

.title {
    display: block;
    font-size: 24px;
    color: #333333;
    line-height: 1;
    margin-bottom: 45px;
    text-align: left;
}

.form-container {
    .item-group {
        text-align: left;
        margin-bottom: 23px;
        border-bottom: 1px solid #E9E9E9;
    }
    input {
        border: none;

        display: block;
        width: 100%;
        padding: 20px 0;
    }
    .label {
        display: block;
        font-size: 16px;
        color: #333333;
        line-height: 1;
    }
}

.code-content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .msg-code {
        margin-right: 10px;
    }

    .code-btn {
        display: block;
        font-size: 14px;
        text-align: center;
        line-height: 1;
        padding: 10px 15px;
        border-radius: 4px;
        flex-shrink: 0;
    }

    .green {
        background-image: linear-gradient(90deg, #40B9FF 0%, #009BFF 100%);
        box-shadow: 0 2px 15px 0 rgba(0,155,255,0.40);
        color: #FFFFFF;
    }

    .gary {
        background-image: #ccc;
        box-shadow: none;
        color: #333333;
    }
}

.pic-modal {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 99;
    left: 0;
    top: 0;
}

.pic-body {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    background: #fff;
    width: 80%;
    border-radius: 8px;
    padding: 20px 16px;
    position: relative;

    .pic-title {
        position: relative;
        line-height: 18px;
        text-align: center;
        color: #4a4a4a;
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 25px;
    }
    .close-btn {
        position: absolute;
        right: 16px;
        top: 20px;
        width: 16px;
        height: 16px;
        background: url('./img/close-btn.png') no-repeat;
        background-size: 100%;
        z-index: 101;
    }

    .cell-bt {
        align-items: center;
        margin-bottom: 10px;
        justify-content: space-between;
    }
    .refresh-code {
        display: block;
        text-align: right;
        font-size: 14px;
        color: #333333;
        margin-bottom: 15px;
    }
    .pic-img-code {
        height: 60px;
    }
    .cell-bt {
        display: flex;
    }
    .pic-code-input {
        padding: 0 5px;
        flex: 1;
        margin-right: 15px;
        height: 40px;
        line-height: 40px;
        border: none;
        border-bottom: 1px solid #d0d0d0;
        font-size: 20px;
        font-weight: 600;
        width: 50%;
    }
    .submit-btn {
        display: block;
        font-size: 14px;
        text-align: center;
        line-height: 40px;
        padding: 0 30px;
        border-radius: 4px;
        flex-shrink: 0;
        background-image: linear-gradient(90deg, #40B9FF 0%, #009BFF 100%);
        box-shadow: 0 2px 15px 0 rgba(0,155,255,0.40);
        color: #FFFFFF;
        border: none;
    }
}

.btn {
    color: #FFFFFF;
    text-align: center;
    font-size: 18px;
    text-align: center;
    line-height: 1;
    padding: 15px 0;
    width: 100%;
    border: none;
    box-shadow: 0 2px 15px 0 rgba(0,155,255,0.40);
    background-image: linear-gradient(90deg, #40B9FF 0%, #009BFF 100%);
    position: fixed;
    left: 0;
    bottom: 0;
}
</style>
